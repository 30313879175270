import React, { Component } from "react";
import hero from "./hero.png";
import logo from "./logo.png";

class SubmissionClosed extends Component {
  render() {
    return (
      <div className="columns is-centered">
        <div className="column is-8">
          <img src={hero} alt="hero" />

          <div className="columns">
            <div className="column">
              <h1
                className="title is-1"
                style={{ marginTop: "2rem", color: "#b50030" }}
              >
                COVID-19 RFP Program:
              </h1>
              <h2
                className="subtitle is-5"
                style={{ color: "#b50030", textDecoration: "underline" }}
              >
                <b>COMMIT</b> <b>(CO</b>vid-19 un
                <b>Met</b> <b>M</b>ed<b>I</b>cal needs and associated
                research ex
                <b>T</b>ension)
              </h2>
              <p>
                The submission window for the COMMIT<sup>TM</sup>, COVID-19 RFP program is now closed.
              </p>
              <p>
                Thank you for your interest.  If you submitted a LOI, we will reach out with the outcome of the reviews at a later time. 
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubmissionClosed;
